export default [
  {
    header: 'market.navigation.all',
  },
  {
    title: 'market.navigation.support',
    tagVariant: 'span',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'market.navigation.faq',
        // icon: 'HelpCircleIcon',
        route: 'faq-index',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'market.navigation.blog',
        // icon: 'FileTextIcon',
        route: 'posts-index',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'market.navigation.telegram_channel',
        href: 'https://t.me/TeLeDive_UA',
        resource: 'Auth',
        action: 'read',
      },
    ],
  },
  {
    title: 'market.navigation.packages',
    icon: 'ShoppingBagIcon',
    route: 'packages-index',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'market.navigation.telegram_ads',
    icon: 'SendIcon',
    route: 'telegramads-index',
    resource: 'Auth',
    action: 'read',
    tag: 'new',
  },
  {
    title: 'market.navigation.globalProject',
    icon: 'ShoppingBagIcon',
    route: 'admin:admin-projects-index',
    action: 'index',
    resource: 'admin:projects',
    tag: 'mass',
  },
]
