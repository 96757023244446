<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="fetchNotifications"
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('cart.notifications.title') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.length }} {{ $t('cart.notifications.label') }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <b-link
        v-for="n in notifications"
        :key="n.subtitle"
        :to="{ name: n.redirect }"
        class="n_link"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="getColor(n)"
            >
              <feather-icon :icon="getIcon(n)" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span
              class="font-weight-bolder"
              v-html="n.description"
            />
          </p>
          <small class="notification-text">{{ n.created_at }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer notify-f">
      <b-button
        v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'admin:account' })"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :to="{ name: 'admin:account-setting', query: { tab: 'notifications' } }"
      >
        {{ $t('cart.notifications.btn') }}
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :to="{ name: 'account:account-setting', query: { tab: 'notifications' } }"
      >
        {{ $t('cart.notifications.btn') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        @click.stop="disableNotifications"
      >
        {{ $t('cart.notifications.btnClear') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import _ from 'lodash'
import { useUtils as useAclUtils } from '@core/libs/acl'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getColor(item) {
      let ret = 'light-warning'
      if (item.type === 'system') {
        ret = 'light-warning'
      }
      if (item.type === 'promo') {
        ret = 'light-info'
      }
      if (item.type === 'hot') {
        ret = 'light-danger'
      }
      return ret
    },
    getIcon(item) {
      let ret = 'AlertTriangleIcon'
      if (item.type === 'system') {
        ret = 'AlertTriangleIcon'
      }
      if (item.type === 'promo') {
        ret = 'AwardIcon'
      }
      if (item.type === 'hot') {
        ret = 'ZapIcon'
      }
      return ret
    },
    async fetchNotifications() {
      await this.$http.get('/api/profile').then(res => {
        const { notifications } = res.data.data
        this.notifications = notifications
      })
    },
    disableNotification(id) {
      this.$http.put(`/api/user/notifications/${id}`, {
        view: false,
      }).then(() => {
        this.fetchNotifications()
      })
    },
    disableNotifications() {
      _.each(this.notifications, n => {
        this.disableNotification(n.id)
      })
    },
  },
  setup() {
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
.dropdown-menu-footer.notify-f {
  display: grid;
  gap:10px;
  grid-template-columns: 2fr 1fr;
}

.n_link {
  position: relative;
  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .media-heading {
    padding-right: 30px;
  }
}
</style>
