<template>
  <div class="navbar-container d-flex content align-items-center">
    <login-modal
      :opened-login-modal.sync="openedLoginModal"
      @refetch-data="fetchEventsList"
    />

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-link
        class="navbar-brand"
        :to="canViewVerticalNavMenuLink({ action: 'index', resource: 'account' }) ? '/catalog/channels/' : '/'"
      >
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
      </b-link>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-toggler class="d-none d-lg-block" />
      <wishlist-dropdown v-if="canViewVerticalNavMenuLink({ action: 'read', resource: 'Auth' })" />
      <cart-dropdown v-if="canViewVerticalNavMenuLink({ action: 'read', resource: 'Auth' })" />
      <notification-dropdown
        v-if="userData"
        :notifications.sync="profile.notifications"
      />
      <b-button
        v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
        variant="primary"
        class="btn-sm btn-balance"
        :to="{ name: 'account:account-setting', query: { tab: 'finance' } }"
      >
        <feather-icon
          class="text-light"
          icon="CreditCardIcon"
          size="21"
        />
        {{ $options.filters.moneyFormat(profile.balance) }}
      </b-button>
      <b-button
        v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'admin:account' })"
        variant="primary"
        class="btn-sm btn-balance"
        :to="{ name: 'admin:account-setting', query: { tab: 'finance' } }"
      >
        <feather-icon
          class="text-light"
          icon="CreditCardIcon"
          size="21"
        />
        {{ $options.filters.moneyFormat(profile.balance) }}
      </b-button>
      <user-dropdown
        v-if="userData"
        :user-data="userData"
      />
      <b-button
        v-else
        variant="primary"
        class="ml-2"
        @click="openedLoginModal = true"
      >
        {{ $t('market.navigation.login') }}
        <feather-icon
          icon="LogInIcon"
          class="ml-1"
        />
      </b-button>
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BNavbarNav, BButton, BImg,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@core/libs/acl'
import LoginModal from '@/views/authentication/LoginModal'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import WishlistDropdown from './components/WishlistDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
// import BalanceDropdown from './components/BalanceDropdown.vue'

export default {
  components: {
    BLink,
    BButton,
    // Navbar Components
    BNavbarNav,
    BImg,
    Locale,
    DarkToggler,
    CartDropdown,
    WishlistDropdown,
    NotificationDropdown,
    UserDropdown,
    // BalanceDropdown,
    LoginModal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      openedLoginModal: false,
      userData: null,
      profile: {
        balance: 0,
        transaction_settings: [],
        notifications: [],
      },
      timer: '',
      appLogoImage: require('@/assets/images/logo/TGlogo.svg'),
    }
  },
  created() {
    this.fetchEventsList()
    this.timer = setInterval(this.fetchEventsList, 5000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    fetchEventsList() {
      if (localStorage.getItem('userData')) {
        this.userData = JSON.parse(localStorage.getItem('userData'))
      }
      if (this.userData) {
        this.$http.get('/api/profile').then(res => {
          const {
            balance, notifications, transaction_settings,
          } = res.data.data
          this.profile = {
            balance, notifications, transaction_settings,
          }

          localStorage.setItem('userData', JSON.stringify(res.data.data))
          this.userData = res.data.data
        })
        this.cancelAutoUpdate()
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer)
    },
  },
  setup() {
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-brand {
  img {
    height: 26px;
    width: auto;
  }
}

.btn-balance {
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 20px;

  @media (max-width: 767.98px) {
    margin: 0 16px;
    padding: 0.486rem 0.729rem;
  }
}
</style>
