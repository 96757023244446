<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        :badge="$store.state.catalog.cartItemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('cart.widget.titles.cart') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $store.state.catalog.cartItemsCount }}
          {{ $t('cart.widget.titles.items') }}
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in items"
        :key="item.product.slug"
      >
        <template #aside>
          <b-link :to="{ name: 'products-show', params: { slug: item.product.slug } }">
            <b-img
              :src="$options.filters.mediaUrl(item.product, 'avatar', '150x150')"
              rounded
              width="62px"
            />
          </b-link>
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item.product)"
        />
        <div class="media-heading">
          <b-link :to="{ name: 'products-show', params: { slug: item.product.slug } }">
            <h6 class="cart-item-title">
              {{ $options.filters.transl(item.product, 'title') }}
            </h6>
          </b-link>
          <b-form-select
            v-model="item.priceType"
            :options="transformPrices(item.product.prices)"
            size="sm"
            @input="updateItems"
          />
        </div>

        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
            v-model="item.quantity"
            min="1"
            size="sm"
            @input="updateItems"
          />
        </div>

        <h5
          v-if="calcDiscount(item)"
          class="cart-item-price"
        >
          <small>
            <del>
              {{ priceFormatted(calcPrice(item)) }}
            </del>
          </small>
          {{ priceFormatted(calcDiscount(item)) }}
        </h5>
        <h5
          v-else
          class="cart-item-price"
        >
          {{ priceFormatted(calcPrice(item)) }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="items.length"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          {{ $t('cart.widget.titles.total') }}
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ totalAmount }}
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'checkout-index' }"
      >
        {{ $t('cart.widget.titles.checkout') }}
      </b-button>
    </li>

    <p
      v-if="!items.length"
      class="m-0 p-1 text-center"
    >
      {{ $t('cart.widget.titles.empty') }}
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton, BFormSelect,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { useEcommerceUi } from '@/views/catalog/useEcommerce'
import _ from 'lodash'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      // this.items.forEach(i => { total += (this.calcDiscount(i) || this.calcPrice(i)) * i.quantity })
      this.items.forEach(i => { total += (this.calcDiscount(i) || this.calcPrice(i)) })
      return this.$options.filters.moneyFormat(total)
    },
  },
  methods: {
    transformPrices(prices) {
      return prices.map(i => ({
        text: this.$t(`catalog.price.types.${i.type}`),
        value: i.type,
      }))
    },
    selectedPrice(item) {
      return _.filter(item.product.prices, o => o.type === item.priceType)
    },
    selectedDiscount(item) {
      return _.filter(item.product.discounts, o => o.type === item.priceType)
    },
    calcPrice(item) {
      return this.selectedPrice(item) && this.selectedPrice(item).length ? this.selectedPrice(item)[0].price * item.quantity : 0
    },
    calcDiscount(item) {
      return this.selectedDiscount(item) && this.selectedDiscount(item).length ? this.selectedDiscount(item)[0].price * item.quantity : 0
    },
    priceFormatted(price) {
      return this.$options.filters.moneyFormat(price)
    },
    fetchItems() {
      this.items = Object.values(JSON.parse(localStorage.getItem('cart')))
    },
    updateItems() {
      const newCart = {}
      _.each(this.items, item => {
        this.$set(newCart, item.product.id, { product: item.product, priceType: item.priceType, quantity: item.quantity })
      })
      localStorage.setItem('cart', JSON.stringify(newCart))
    },
    removeItemFromCart(product) {
      this.handleCartActionClick(product, '1day', 1)
      setTimeout(() => {
        this.fetchItems()
      }, 350)
    },
  },
  setup() {
    const { handleCartActionClick } = useEcommerceUi()

    return {
      handleCartActionClick,
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
