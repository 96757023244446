<template>
  <div class="footer-row">
    <b-row v-if="showFooter">
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <template v-if="navigations && navigations[0]">
          <h4>{{ navigations[0].title }}</h4>
          <ul v-if="navigations[0].items">
            <li
              v-for="item in navigations[0].items"
              :key="item.id"
            >
              <a
                v-if="item.blank"
                :href="item.href"
                target="_blank"
              >
                {{ item.title }}
              </a>
              <b-link
                v-else
                :to="item.href"
              >
                {{ item.title }}
              </b-link>
            </li>
          </ul>
        </template>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <template v-if="navigations && navigations[1]">
          <h4>{{ navigations[1].title }}</h4>
          <ul v-if="navigations[1].items">
            <li
              v-for="item in navigations[1].items"
              :key="item.id"
            >
              <a
                v-if="item.blank"
                :href="item.href"
                target="_blank"
              >
                {{ item.title }}
              </a>
              <b-link
                v-else
                :to="item.href"
              >
                {{ item.title }}
              </b-link>
            </li>
          </ul>
        </template>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <template v-if="navigations && navigations[2]">
          <h4>{{ navigations[2].title }}</h4>
          <ul v-if="navigations[2].items">
            <li
              v-for="item in navigations[2].items"
              :key="item.id"
            >
              <a
                v-if="item.blank"
                :href="item.href"
                target="_blank"
              >
                {{ item.title }}
              </a>
              <b-link
                v-else
                :to="item.href"
              >
                {{ item.title }}
              </b-link>
            </li>
          </ul>
        </template>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <template v-if="navigations && navigations[3]">
          <h4>{{ navigations[3].title }}</h4>
          <ul v-if="navigations[3].items">
            <li
              v-for="item in navigations[3].items"
              :key="item.id"
            >
              <a
                v-if="item.blank"
                :href="item.href"
                target="_blank"
              >
                {{ item.title }}
              </a>
              <b-link
                v-else
                :to="item.href"
              >
                {{ item.title }}
              </b-link>
            </li>
          </ul>
        </template>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <template v-if="navigations && navigations[4]">
          <h4>{{ navigations[4].title }}</h4>
          <ul v-if="navigations[4].items">
            <li
              v-for="item in navigations[4].items"
              :key="item.id"
            >
              <a
                v-if="item.blank"
                :href="item.href"
                target="_blank"
              >
                {{ item.title }}
              </a>
              <b-link
                v-else
                :to="item.href"
              >
                {{ item.title }}
              </b-link>
            </li>
          </ul>
        </template>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <b-img
          src="/icons/payments.png"
          class="payments mb-1"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        TeLeAds.com.ua © {{ new Date().getFullYear() }}
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <span class="float-md-right">Developed by
          <b-link
            class="ml-25"
            href="https://abvv.group/"
            target="_blank"
          >ABVV.Group</b-link>
        </span>
      </b-col>
    </b-row>

    <b-modal
      v-if="showCookie"
      id="modal-center"
      ref="cookiesModal"
      centered
      title="Cookie Policy"
      hide-footer
    >
      <b-card-text>
        Цей веб-сайт використовує файли cookie для персоналізації вмісту та аналізу трафіку,
        щоб запропонувати вам кращий досвід користування сервісом.
      </b-card-text>
      <b-button
        class="mt-3"
        variant="primary"
        block
        @click="closeCookiesModal"
      >
        Погоджуюсь
      </b-button>
    </b-modal>
    <b-modal
      v-if="showAfterAuth"
      id="modal-center"
      ref="afterAuthModal"
      centered
      :title="`Запуск рекламної кампанії в Telegram під ключ`"
      hide-footer
      @hidden="closeAfterAuthModal"
      @ok="closeAfterAuthModal"
    >
      <img src="/img/photo1715428574.jpeg" />
      <b-card-text>
        <p><b>Закупляємо рекламу в телеграм-каналах для вашого бізнесу та каналів</b></p>
        <p>Персональний менеджер та готові тарифні пропозиції допоможуть вам отримати швидкий та якісний результат.</p>
        <p>
          Хочете замовити рекламу або потрібна консультація <br>👉 переходьте до розділу "
          <a @click.prevent="applyAfterAuthModal('https://teleads.com.ua/packages')">Тарифні пропозиції</a>
          " або звертайтесь до менеджера
          <a @click.prevent="applyAfterAuthModal('https://t.me/teleads')">@TeLeAds</a>
        </p>
      </b-card-text>
      <b-row>
        <b-col cols="12" md="4">
          <b-button
            class="mt-1"
            variant="primary"
            block
            @click="applyAfterAuthModal('https://teleads.com.ua/packages')"
          >
            Про послугу
          </b-button>
        </b-col>
        <b-col cols="12" md="4">
          <b-button
            class="mt-1"
            variant="primary"
            block
            @click="applyAfterAuthModal('https://t.me/TeLeAds_UA')"
          >
            Канал біржі
          </b-button>
        </b-col>
        <b-col cols="12" md="4">
          <b-button
            class="mt-1"
            variant="primary"
            block
            @click="applyAfterAuthModal('https://t.me/teleads')"
          >
            Зв'язок
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import GlobalMixin from '@mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      navigations: null,
    }
  },
  computed: {
    showFooter() {
      return !this.userData || !this.userData.belongs_to_administrations
    },
    showCookie() {
      return !this.userData && !this.$cookies.get('cookiesModal')
    },
    showAfterAuth() {
      return this.userData && !this.$cookies.get('showAfterAuth') && this.$route.name !== 'telegramads-index'
    },
  },
  beforeCreate() {
    if (!this.navigations) {
      this.$http.get('/api/promo/navigations').then(res => {
        this.navigations = res.data.data
        const prc = JSON.parse(localStorage.getItem('prc')) ?? {}
        localStorage.setItem('prc', JSON.stringify({ ...prc, ...res.data.prc }))
      })
    }
  },
  mounted() {
    if (this.showCookie) {
      // this.$refs.cookiesModal.show()

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Cookie policy',
          text: 'Цей веб-сайт використовує файли cookie для персоналізації вмісту та аналізу трафіку, щоб запропонувати вам кращий досвід користування сервісом.',
          icon: 'AlertCircleIcon',
          variant: 'info',
        },
      }, {
        timeout: 10000,
        position: 'bottom-left',
      })
    }
    if (this.showAfterAuth) {
      this.$refs.afterAuthModal.show()
    }
  },
  methods: {
    closeCookiesModal() {
      this.$cookies.set('cookiesModal', 1)
      this.$refs.cookiesModal.hide()
    },
    closeAfterAuthModal() {
      this.$cookies.set('showAfterAuth', 1)
      this.$refs.afterAuthModal.hide()
    },
    applyAfterAuthModal(url = null) {
      this.$cookies.set('showAfterAuth', 1)
      this.$refs.afterAuthModal.hide()

      window.open(url || 'https://t.me/TeLeAds_UA', 'blank')
    },
  },
}
</script>

<style lang="scss">
#modal-center .modal-dialog {
  max-width: 100%;
  width: 700px;
}

#modal-center .modal-dialog img {
  width: calc(100% + 2.8rem);
  max-width: calc(100% + 2.8rem);
  height: auto;
  margin: -0.8rem -1.4rem 1.4rem;
}

.footer-row {
  padding: 2em 0 0;

  .row > div {
    padding-bottom: 2em;
  }

  .payments {
    max-width: 100%;
    width: 140px;
    display: block;
  }

  h4 {
    margin: 0 0 20px;
  }

  ul {
    list-style: none;
    padding: 0 0;
    margin: 0 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
      color: var(--dark);
    }
  }
}

.dark-layout {
  .footer-row {
    ul {
      a {
        color: var(--light);
      }
    }
  }
}
</style>
