import store from '@/store'
// import { useRouter } from '@core/utils/utils'

export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = product => {
    return store.dispatch('catalog/addProductInWishlist', { product })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = productId => {
    return store.dispatch('catalog/removeProductFromWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = item => {
    return store.dispatch('catalog/addProductInCart', { item })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => {
    return store.dispatch('catalog/removeProductFromCart', { productId })
  }

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
  }
}

export const useEcommerceUi = () => {
  // const { router } = useRouter()

  const toggleProductInWishlist = product => {
    const { addProductInWishlist, removeProductFromWishlist } = useEcommerce()
    const wishlist = JSON.parse(localStorage.getItem('wishlist')) ?? {}

    if (Object.values(wishlist).filter(item => item.product.id === product.id).length) {
      removeProductFromWishlist(product.id).then(res => {
        product.isInWishlist = false
        store.commit('catalog/UPDATE_WISHLIST_ITEMS_COUNT', store.state.catalog.wishlistItemsCount - 1)
        store.commit('catalog/UPDATE_WISHLIST_ITEMS', res)
      })
    } else {
      addProductInWishlist(product).then(res => {
        product.isInWishlist = true
        store.commit('catalog/UPDATE_WISHLIST_ITEMS_COUNT', store.state.catalog.wishlistItemsCount + 1)
        store.commit('catalog/UPDATE_WISHLIST_ITEMS', res)
      })
    }
  }

  const handleCartActionClick = (product, priceType, quantity) => {
    const { addProductInCart, removeProductFromCart } = useEcommerce()
    const cart = JSON.parse(localStorage.getItem('cart')) ?? {}

    if (Object.values(cart).filter(item => item.product.id === product.id).length) {
      removeProductFromCart(product.id).then(res => {
        store.commit('catalog/UPDATE_CART_ITEMS_COUNT', store.state.catalog.cartItemsCount - 1)
        store.commit('catalog/UPDATE_CART_ITEMS', res)
      })
    } else {
      const item = {
        product,
        priceType,
        quantity,
      }
      addProductInCart(item).then(res => {
        // product.isInCart = true
        store.commit('catalog/UPDATE_CART_ITEMS_COUNT', store.state.catalog.cartItemsCount + 1)
        store.commit('catalog/UPDATE_CART_ITEMS', res)
      })
    }
  }

  return {
    toggleProductInWishlist,
    handleCartActionClick,
  }
}
