<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        :badge="$store.state.catalog.wishlistItemsCount"
        class="text-body"
        icon="HeartIcon"
        size="21"
      />
    </template>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in items"
        :key="item.product.slug"
      >
        <template #aside>
          <b-link :to="{ name: 'products-show', params: { slug: item.product.slug } }">
            <b-img
              :src="$options.filters.mediaUrl(item.product, 'avatar', '150x150')"
              rounded
              width="62px"
            />
          </b-link>
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeProductFromWishlist(item.product.id)"
        />
        <div
          class="media-heading"
          style="width: auto"
        >
          <b-link :to="{ name: 'products-show', params: { slug: item.product.slug } }">
            <h6 class="cart-item-title">
              {{ item.product.title }}
            </h6>
            <small class="cart-item-by">{{ item.product.exception }}</small>
          </b-link>
        </div>
        <h5 class="cart-item-price">
          {{ calcPrice(item) }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <p
      v-if="!items.length"
      class="m-0 p-1 text-center"
    >
      {{ $t('cart.widget.titles.wishlistempty') }}
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BMedia, BLink, BImg,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BImg,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  methods: {
    calcPrice(item) {
      return this.$options.filters.moneyFormat(item.product.prices[0].price)
    },
    fetchItems() {
      this.items = Object.values(JSON.parse(localStorage.getItem('wishlist')))
    },
    removeProductFromWishlist(productId) {
      this.$store.dispatch('catalog/removeProductFromWishlist', { productId })
        .then(() => {
          const itemIndex = this.items.findIndex(p => p.product.id === productId)
          this.items.splice(itemIndex, 1)

          this.$store.commit('catalog/UPDATE_WISHLIST_ITEMS_COUNT', this.items.length)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
