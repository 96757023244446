export default [
  {
    title: 'market.navigation.admin.dashboard',
    route: 'home',
    icon: 'HomeIcon',
    action: 'index',
    resource: 'admin:dashboard',
  },
  // {
  //   title: 'market.navigation.my_dashboard',
  //   route: 'dashboard',
  //   icon: 'HomeIcon',
  //   action: 'index',
  //   resource: 'dashboard',
  // },
  {
    title: 'market.navigation.dashboard',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth',
  },
]
