<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-group
      v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'products' })"
      id="dropdown-group-1"
      :header="$t('account.navigation.group.buyer')"
    >
      <b-dropdown-item
        :to="{ name: 'account:projects-index'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="BriefcaseIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.projects') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'account:templates-index'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="LayersIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.templates') }}
        </span>
      </b-dropdown-item>
    </b-dropdown-group>

    <b-dropdown-group
      v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'products' })"
      id="dropdown-group-2"
      :header="$t('account.navigation.group.owner')"
    >
      <b-dropdown-item
        :to="{ name: 'account:products-index'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="ListIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.channels') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'account:tasks-index'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="BriefcaseIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.orders') }}
          <b-badge
            v-if="userData.new_tasks"
            variant="success"
            v-text="userData.new_tasks"
          />
        </span>
      </b-dropdown-item>
    </b-dropdown-group>

    <b-dropdown-group
      v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
      id="dropdown-group-3"
      :header="$t('account.navigation.group.profile')"
    >
      <b-dropdown-item
        :to="{ name: 'account:account-setting', query: { tab: 'finance' } }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="CreditCardIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.finance') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'account:account-setting', query: { tab: 'referral' } }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="UsersIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.referral') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'account:account-setting', query: { tab: 'notifications' } }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="AlertCircleIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.titles.notify.title') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'account:account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.setting') }}
        </span>
      </b-dropdown-item>
    </b-dropdown-group>

    <b-dropdown-group
      v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'admin:account' })"
      id="dropdown-group-3"
      :header="$t('account.navigation.group.profile')"
    >
      <b-dropdown-item
        :to="{ name: 'admin:account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.setting') }}
        </span>
      </b-dropdown-item>
    </b-dropdown-group>

    <b-dropdown-group
      v-if="canViewVerticalNavMenuLink({ action: 'index', resource: 'posts' })"
      id="dropdown-group-4"
      :header="$t('account.navigation.group.other')"
    >
      <b-dropdown-item
        :to="{ name: 'posts-index' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="FileTextIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.blog') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'faq-index' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="20"
          icon="HelpCircleIcon"
          class="mr-50"
        />
        <span>
          {{ $t('account.navigation.item.faq') }}
        </span>
      </b-dropdown-item>
    </b-dropdown-group>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="20"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>
        {{ $t('account.navigation.item.logout') }}
      </span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BDropdownGroup, BBadge,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownGroup,
    BAvatar,
    BBadge,
  },
  // data() {
  //   return {
  //     userData: JSON.parse(localStorage.getItem('userData')),
  //   }
  // },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)
      this.$cookies.set('showAfterAuth', 0)

      // Redirect to login page
      this.$router.push({ name: 'home' })
      window.location.reload()
    },
  },
  setup() {
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style type="text/scss">
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: auto;
  min-width: 200px;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu i,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu svg {
  height: 20px;
  width: 20px;
  font-size: 20px;
  vertical-align: top;
}
</style>
