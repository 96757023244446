export default [
  {
    header: 'market.navigation.my_client',
  },
  {
    title: 'market.navigation.projects',
    tagVariant: 'span',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'market.navigation.my_projects',
        route: 'account:projects-index',
        action: 'index',
        resource: 'projects',
      },
      {
        title: 'market.navigation.my_templates',
        route: 'account:templates-index',
        action: 'index',
        resource: 'templates',
      },
      {
        title: 'market.navigation.my_channels',
        route: 'account:products-index',
        action: 'index',
        resource: 'products',
      },
      {
        title: 'market.navigation.my_tasks',
        route: 'account:tasks-index',
        action: 'index',
        resource: 'projects',
      },
    ],
  },
]
