export default [
  {
    header: 'market.navigation.client',
  },
  {
    title: 'market.navigation.catalog',
    tagVariant: 'span',
    icon: 'ListIcon',
    children: [
      {
        title: 'market.navigation.channels',
        route: 'products-index',
        resource: 'Auth',
        action: 'read',
      },
      {
        title: 'market.navigation.hot_channels',
        route: 'products-hot',
        resource: 'Auth',
        action: 'read',
      },
    ],
  },
]
